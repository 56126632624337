/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import CallCenterHero from '../images/backgrounds/call-center-hero.jpg'
import CallCenterHeroMobile from '../images/backgrounds/call-center-hero-mobile.jpg'
import CallCenterLady from '../images/people/call-center-lady.png'
import customer from '../images/people/on-with-customer-service.png'
import b2b from '../images/people/b2b.png'
import medicare from '../images/people/medicare.png'
import one2million from '../images/illustrations/1-2-million.png'
import thirtyPercent from '../images/illustrations/30-percent.png'
import threeFifteenK from '../images/illustrations/315k.png'
import HubspotForm from '../components/Form/HubspotForm'
import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'
import {
  Billboard,
  SplitContent,
  VariableContent,
  List,
  ListItem,
  Column,
  Columns,
  Typography,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'
const Home = () => {
  const windowSize = useWindowSize()
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Sales Solutions | Clearlink',
          description:
            'Our award-winning call center solutions deliver real customers to some of the world’s biggest brands. Contact us to find out more of what sets us apart.',
          canonical: 'https://www.clearlink.com/',
          robots: 'follow,index',
        },
        path: '/our-services',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Sales Solutions | Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          alignImageToBottom
          color="white"
          className="content-transparent-desktop mobile-image-full"
          variant="full"
          image={
            <img
              src={CallCenterHero}
              alt="employees working in an office environment"
            />
          }
          mobileImage={
            <img
              src={CallCenterHeroMobile}
              alt="employees working in an office environment"
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
              style={{ marginBottom: '20px' }}
            >
              <Typography variant="h1">
                Smart sales solutions for every business
                <span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography variant="h4" style={{ marginBottom: '26px' }}>
                Our award-winning sales center does it better than anyone else.
                We use every tool and training method to ensure our agents stay
                ahead of the curve and gain valuable customers that stick.
              </Typography>
              <LinkButton
                variant="hero"
                to="#hubpsot-form-section"
                color="primary"
              >
                Sell with us
              </LinkButton>
            </div>
          }
        />

        <VariableContent
          backgroundColor="primary"
          alignMainContent="center"
          mainContent={
            <>
              <div
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h2">
                  A sales center that closes
                  <span className="white-punctuation-large">.</span>
                </Typography>
              </div>
            </>
          }
        >
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={false}>
              <Column>
                <img
                  src={one2million}
                  alt="man on phone with customer service"
                  style={{ marginBottom: '20px' }}
                />
                <Typography variant="h4">Annual Call Volume</Typography>
              </Column>
              <Column>
                <img
                  src={thirtyPercent}
                  alt="man on phone with customer service"
                  style={{ marginBottom: '20px' }}
                />
                <Typography variant="h4">Conversion/Closing Rate</Typography>
              </Column>
              <Column>
                <img
                  src={threeFifteenK}
                  alt="medicare customer"
                  style={{ marginBottom: '20px' }}
                />
                <Typography variant="h4">Annual Closed Sales</Typography>
              </Column>
            </Columns>
          </div>
        </VariableContent>
        <SplitContent
          backgroundColor="white"
          alignImageToBottom
          className="image-bottom-fix"
          mobileAlignImageToBottom
          image={<img src={CallCenterLady} alt="Call center employee" />}
          color="black"
          mainContent={
            <>
              <div
                data-sal="slide-left"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h1">
                  Solutions that set us apart
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  Our robust training processes and proven coaching techniques
                  develop agents with an advantage. We consistently outperform
                  our competitors and drive customer acquisition in a variety of
                  verticals. Our sales agents are experts in your brand and
                  products and guide your future customers through their
                  purchasing journey. We take great care to train our agents
                  with unique methods including:
                </Typography>
                <List>
                  <ListItem>Speech trend analysis</ListItem>
                  <ListItem>Real-time agent reporting</ListItem>
                  <ListItem>Coaching dashboards</ListItem>
                  <ListItem>Live dials</ListItem>
                  <ListItem>Proprietary tools</ListItem>
                </List>
                <LinkButton
                  to="#hubpsot-form-section"
                  style={{ marginTop: '10px' }}
                  color="primary"
                >
                  Find out how
                </LinkButton>
              </div>
            </>
          }
        />
        <VariableContent
          backgroundColor="black"
          alignMainContent="center"
          mainContent={
            <>
              <div
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h2">
                  Every vertical
                  <span className="blue-punctuation-large">.</span> Every lead
                  <span className="blue-punctuation-large">.</span> Every sale
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  Our sales agents handle multiple products, services, and
                  industries and always know the best way to close the deal.
                </Typography>
              </div>
            </>
          }
        >
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={true}>
              <Column>
                <img src={customer} alt="man on phone with customer service" />
                <Typography variant="h4" style={{ marginTop: '10px' }}>
                  Residential
                </Typography>
                <Typography variant="body">
                  We have decades of experience in selling home services and
                  other residential products to millions of users
                </Typography>
              </Column>
              <Column>
                <img src={b2b} alt="lady on phone" />
                <Typography variant="h4" style={{ marginTop: '10px' }}>
                  Business to Business
                </Typography>
                <Typography variant="body">
                  Whether it’s a larger company or a local restaurant around the
                  corner, we know how to close the sale
                </Typography>
              </Column>
              <Column>
                <img src={medicare} alt="medicare customer" />
                <Typography variant="h4" style={{ marginTop: '10px' }}>
                  Medicare
                </Typography>
                <Typography variant="body">
                  Complicated services like Medicare require knowledgeable and
                  experienced agents to guide users through the purchase
                </Typography>
              </Column>
            </Columns>
          </div>
        </VariableContent>
        <HubspotForm
          heading="Get more customers"
          punctuation="."
          subheading="Partner with us and share in all the benefits of our world-class sales center. You’ll experience high conversions and customer care that always puts the individual first."
        />
      </>
    ),
    disableBreadcrumbs: true,
  }
  return <Layout {...layoutProps} />
}
export default Home
