import React, { useEffect, useState } from 'react'
import { Typography, VariableContent } from '@leshen/gatsby-theme-leshen'

const loadHubspotFormScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    script.charset = 'utf-8'
    script.async = true
    script.onload = resolve
    script.onerror = reject

    document.body.appendChild(script)
  })
}

const VariableContentWithHubSpotForm = ({
  heading,
  punctuation,
  subheading,
}) => {
  const [formLoaded, setFormLoaded] = useState(false)

  useEffect(() => {
    const loadScript = async () => {
      try {
        await loadHubspotFormScript()
        setFormLoaded(true)
      } catch (error) {
        console.error('Failed to load HubSpot form script:', error)
      }
    }

    loadScript()

    return () => {
      // Clean up script tag if component unmounts
      const scriptElement = document.querySelector(
        'script[src="//js.hsforms.net/forms/embed/v2.js"]'
      )
      if (scriptElement) {
        scriptElement.remove()
      }
    }
  }, [])

  useEffect(() => {
    if (window.hbspt && formLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '43602123',
        formId: '5e8a7cb1-5f3f-40ea-913a-c0daab9427fb',
        target: '#hubspot-form-container',
      })
    }
  }, [formLoaded])

  return (
    <>
      <div id="hubpsot-form-section" />
      <VariableContent
        alignMainContent="center"
        mainContent={
          <>
            <Typography variant="h2">
              {heading}
              <span className="blue-punctuation-large">{punctuation}</span>
            </Typography>
            {subheading && <Typography variant="body">{subheading}</Typography>}
          </>
        }
      >
        <div id="hubspot-form-container" className="hubspot-form" />
      </VariableContent>
    </>
  )
}

export default VariableContentWithHubSpotForm
